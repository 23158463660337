import React, { useState } from 'react'
import {Helmet} from 'react-helmet'
import { setSessionPassword } from '../utils/utils'

// import { getQueryPassword, setSessionPassword } from '../utils/utils'
// import PersonalImage from '../../../images/leland.jpeg'
 
const styles = {
  page: {
    height: '100vh',
    background: '#e9f4ff',
    backgroundImage: 'linear-gradient(to right top, #9d9dff, #96a8ff, #92b2ff, #92bbff, #95c3ff, #91ccff, #90d4ff, #93dbff, #91e5ff, #91eeff, #95f7ff, #9dffff)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loginContainer: {
    background: '#fff',
    padding: '1rem',
    borderRadius: '4px',
    boxShadow: '1px 1px 10px 0 rgba(128,128,128,0.2)',
    maxWidth: '320px'
  },
  label: {
    display: 'block',
    marginTop: '0',
    marginBottom: '0.5rem'
  },
  input: {
    width: '100%',
    display: 'block',
    maxWidth: '288px',
    fontSize: '18px',
    lineHeight: '32px',
    height: '32px',
    paddingLeft: '8px',
    borderRadius: '4px',
    border: '1px solid #9dceff'
  },
  inputFocus: {
    borderColor: '#51a8ff'
  },
  button: {
    width: '100%',
    height: '48px',
    fontSize: '1rem',
    background: '#9dceff',
    border: '1px solid #e9f4ff',
    borderRadius: '4px',
    marginTop: '16px',
    fontWeight: '300',
    fontFamily: 'Karla'
  },
  buttonHover: {
    background: '#84c1ff',
    color: '#000000',
    cursor: 'pointer'
  },
  link: {
    color: '#fff',
    fontFamily: 'sans-serif'
  },
  linkHover: {
    color: 'dodgerblue'
  }
};

const PasswordProtect = () => {
  const [password, setPassword] = useState('');
  const [isButtonHovered, buttonHover] = useState(false);
  const [hasFocus, setFocus] = useState(false);

  // console.log(location);
  // const queryPassword = getQueryPassword(location);

  // if (queryPassword) {
  //   console.log(queryPassword);
  //   setSessionPassword(queryPassword)
  // }

  const onSubmit = event => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload();
  };

  return (
    <div style={styles.page}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex" />
        <title>Leland Riordan - Portfolio - Login</title>
      </Helmet>
      <div style={styles.loginContainer}>
        {/* <h1>Leland Riordan</h1> */}

        <form onSubmit={onSubmit} style={{ marginBottom: '2rem', width: '300px', maxWidth: '100%' }}>
          <label htmlFor="password" style={styles.label}>Enter Password</label>
          {/* 
            Hidden password input to disable chrome save password prompt
          
          */}
          <input type="password" style={{display:"none"}}/>
          <input
            name="password"
            id="password"
            type="password"
            value={password}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={event => setPassword(event.target.value)}
            style={{
              ...styles.input,
              ...(hasFocus ? styles.inputFocus : null)
            }}
            placeholder='Password&hellip;'
          />

          <button
            type="submit"
            style={{
                ...styles.button,
                ...(isButtonHovered ? styles.buttonHover : null)
            }}
            onMouseEnter={() => buttonHover(true)}
            onMouseLeave={() => buttonHover(false)}
          >
          Submit
          </button>
        </form>

        <a href="mailto:leland.riordan@gmail.com?subject=Request%20Portfolio%20Access">
            Request Access &rarr;
        </a>
      </div>
    </div>
  );
};

export default PasswordProtect;
 